<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Public URL </v-list-item-title>
            <v-list-item-subtitle>
              <a target="_blank" :href="`${artistURL}/${item.slug}`">{{ `${artistURL}/${item.slug}` }}</a>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="item.type === 'individual'">
            <v-list-item-title> First Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.firstName }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="item.type === 'individual'">
            <v-list-item-title> Last Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.lastName }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Slug </v-list-item-title>
            <v-list-item-subtitle>{{ item.slug }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Type </v-list-item-title>
            <v-list-item-subtitle> {{ item.type | capitalize }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> User type </v-list-item-title>
            <v-list-item-subtitle>{{ item.userType | capitalize }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Country </v-list-item-title>
            <v-list-item-subtitle>{{ item.countryCode }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Phone </v-list-item-title>
            <v-list-item-subtitle>{{ item.phone }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> User visibility </v-list-item-title>
            <v-list-item-subtitle> {{ item.userVisibility ? 'Yes' : 'No' }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.userType === 'host'">
            <v-list-item-title> Trustworthy host </v-list-item-title>
            <v-list-item-subtitle> {{ item.isTrustworthy ? 'Yes' : 'No' }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.heardFrom">
            <v-list-item-title> How did you hear about Tutti? </v-list-item-title>
            <v-list-item-subtitle> {{ item.heardFrom }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.heardFromValue">
            <v-list-item-title> {{ itemsObject[item.heardFrom] }} </v-list-item-title>
            <v-list-item-subtitle> {{ item.heardFromValue }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list-meta :item="item" />

        <vx-card-list v-if="(item.users || []).length" title="Users" :item="item" :tab="0">
          <v-list v-for="(info, index) in item.users" :key="index" dense>
            <v-list-item :to="$toView('user', info.user._id)">
              <v-list-item-title> Name </v-list-item-title>
              <v-list-item-subtitle> {{ info.user?.displayName }} </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title> Email </v-list-item-title>
              <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(info.user.email)">
                {{ info.user?.email }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item :to="$toView('role', info.role._id)">
              <v-list-item-title> Role </v-list-item-title>
              <v-list-item-subtitle> {{ info.role?.name }} </v-list-item-subtitle>
            </v-list-item>

            <v-divider v-if="index !== item.users.length - 1" />
          </v-list>
        </vx-card-list>

        <vx-card-list v-if="(item.invitations || []).length" title="Invitations" :item="item" :tab="0">
          <v-list v-for="(info, index) in item.invitations" :key="index" dense>
            <v-list-item>
              <v-list-item-title> Email </v-list-item-title>
              <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(info.email)">
                {{ info.email }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title> Role </v-list-item-title>
              <v-list-item-subtitle> {{ info.role?.name }} </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title> Status </v-list-item-title>
              <v-list-item-subtitle> {{ info.status }} </v-list-item-subtitle>
            </v-list-item>

            <v-divider v-if="index !== item.invitations.length - 1" />
          </v-list>
        </vx-card-list>

        <vx-card-list title="Availability" :item="item" :tab="1">
          <v-list-item v-for="(availability, index) in item.availabilities" :key="index">
            <v-list-item-title>
              {{ availability.day }}
            </v-list-item-title>

            <v-list-item-subtitle>
              <template v-if="availability.enabled">
                <template v-if="availability.all">24 hour</template>

                <template v-for="(slot, slotIndex) in availability.slots" v-else>
                  <div :key="slotIndex">{{ slot.startHour | hour24 }} - {{ slot.endHour | hour24 }}</div>
                </template>
              </template>

              <template v-else>Closed</template>
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <v-card v-if="item.media[0]" class="mb-4">
          <v-card-title class="primary--text">
            Photo
            <v-spacer />
            <vx-btn color="primary" icon :to="$toEdit('account', item._id, 0)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </vx-btn>
          </v-card-title>

          <v-card-text>
            <vx-img width="200px" auto :absolute="false" :src="$getAccountImage(item, '400')" />
          </v-card-text>
        </v-card>

        <vx-card-list v-if="$hasValue(item.socialLink)" title="Social Links" :item="item" :tab="1">
          <v-list-item v-for="(value, name) in item.socialLink" :key="name">
            <v-list-item-title> {{ name | capitalize }} </v-list-item-title>
            <v-list-item-subtitle> {{ value }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="$hasValue(calendarInfo)" title="Calendar Integration Details">
          <v-list-item>
            <v-list-item-title v-if="calendarInfo.name"> Name</v-list-item-title>
            <v-list-item-subtitle> {{ calendarInfo.name }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="calendarInfo.meta?.email">
            <v-list-item-title> Email</v-list-item-title>
            <v-list-item-subtitle> {{ calendarInfo.meta.email }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item
            v-if="calendarInfo.locationIds?.length > 0"
            :to="$toList('location', { _id: calendarInfo.locationIds })"
          >
            <v-list-item-title> Locations</v-list-item-title>
            <v-list-item-subtitle>
              {{ calendarInfo.locationIds?.length }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="calendarInfo.spaceIds?.length > 0" :to="$toList('space', { _id: calendarInfo.spaceIds })">
            <v-list-item-title> Spaces</v-list-item-title>
            <v-list-item-subtitle> {{ calendarInfo.spaceIds?.length }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12" sm="6">
        <vx-card-list title="References" hide-edit :item="item">
          <v-list-item :to="$toList('user', { _id: { $in: item.users.map(x => x.user._id) } })">
            <v-list-item-title> Users </v-list-item-title>
            <v-list-item-subtitle>{{ count.users }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('contact', { hostAccount: item._id })">
            <v-list-item-title> Contacts </v-list-item-title>
            <v-list-item-subtitle>{{ count.contacts || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('invitation', { account: this.$route.params.id })">
            <v-list-item-title> Invitations </v-list-item-title>
            <v-list-item-subtitle>{{ count.onboardingRequests }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('message', { accountId: this.$route.params.id })">
            <v-list-item-title> Messages </v-list-item-title>
            <v-list-item-subtitle>{{ count.messages }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.userType === 'host'" :to="$toList('booking', { accountId: this.$route.params.id })">
            <v-list-item-title> Bookings recieved </v-list-item-title>
            <v-list-item-subtitle>{{ count.bookings }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('review', { to: this.$route.params.id })">
            <v-list-item-title> Reviews </v-list-item-title>
            <v-list-item-subtitle>{{ count.reviews }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { favorite: this.$route.params.id })">
            <v-list-item-title> Favourite spaces </v-list-item-title>
            <v-list-item-subtitle>{{ count.favoriteSpaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('booking_status', { accountId: this.$route.params.id, type: 'all' })">
            <v-list-item-title> Enquiry Statuses </v-list-item-title>
            <v-list-item-subtitle>{{ count.bookingStatus || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('enquiry_type', { accountId: this.$route.params.id })">
            <v-list-item-title> Enquiry Types </v-list-item-title>
            <v-list-item-subtitle>{{ count.enquiryTypes || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.userType === 'host'" :to="$toList('payment', { accountId: this.$route.params.id })">
            <v-list-item-title> External payments </v-list-item-title>
            <v-list-item-subtitle>{{ count.payments }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.userType === 'host'" title="Listings" hide-edit :item="item">
          <v-list-item :to="$toList('location', { accountId: this.$route.params.id })">
            <v-list-item-title> Locations </v-list-item-title>
            <v-list-item-subtitle>{{ count.locations }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { accountId: this.$route.params.id })">
            <v-list-item-title> Total spaces </v-list-item-title>
            <v-list-item-subtitle>{{ count.spaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { accountId: this.$route.params.id, isVerified: true })">
            <v-list-item-title> Verified spaces </v-list-item-title>
            <v-list-item-subtitle>{{ count.verifiedSpaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { accountId: this.$route.params.id, status: 'open' })">
            <v-list-item-title> Open spaces </v-list-item-title>
            <v-list-item-subtitle>{{ count.openSpaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { accountId: this.$route.params.id, status: 'closed' })">
            <v-list-item-title> Closed spaces </v-list-item-title>
            <v-list-item-subtitle>{{ count.closedSpaces }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-else title="Bookings" hide-edit :item="item">
          <v-list-item>
            <v-list-item-title> All </v-list-item-title>
            <v-list-item-subtitle>{{ count.bookings }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Drafted </v-list-item-title>
            <v-list-item-subtitle>{{ count.drafted }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Request Sent </v-list-item-title>
            <v-list-item-subtitle>{{ count.requestSent }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Cancelled </v-list-item-title>
            <v-list-item-subtitle>{{ count.cancelled }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Paid </v-list-item-title>
            <v-list-item-subtitle>{{ count.paid }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Completed </v-list-item-title>
            <v-list-item-subtitle>{{ count.completed }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list
          v-if="$hasValue(item, ['isVerified', 'isResponsive', 'isCertified'])"
          title="Badges"
          :item="item"
          hide-edit
        >
          <v-list-item v-if="item.isVerified" class="success">
            <v-list-item-icon>
              <vx-icon color="white"> mdi-check</vx-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text"> Verified</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="item.isResponsive" class="yellow accent-3">
            <v-list-item-icon>
              <vx-icon color="black">mdi-lightning-bolt</vx-icon>
            </v-list-item-icon>
            <v-list-item-title> Responsive </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="item.isCertified" class="info">
            <v-list-item-icon>
              <vx-icon color="white"> mdi-police-badge-outline</vx-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text"> Certified</v-list-item-title>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Search count" hide-edit :item="item">
          <v-list-item v-for="(discipline, index) in disciplines" :key="index">
            <v-list-item-title> {{ discipline.name }} </v-list-item-title>
            <v-list-item-subtitle>{{ $get(item, `stats.attributes[${discipline._id}]`) || 0 }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.userType === 'artist'" title="Artist follow-up questions" hide-edit :item="item">
          <v-list-item v-for="(value, key) in item.followUp" :key="key">
            <v-list-item-title> {{ key | startcase }} </v-list-item-title>
            <v-list-item-subtitle>{{ value }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.stats" title="Response time/rate" :item="item" hide-edit>
          <!-- <v-list-item>
            <v-list-item-title> Created date</v-list-item-title>
            <v-list-item-subtitle>{{ item.createdAt | date }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Last modified</v-list-item-title>
            <v-list-item-subtitle>{{ item.updatedAt | date }} </v-list-item-subtitle>
          </v-list-item> -->

          <v-list-item v-for="(value, name) in item.stats.responseTime" :key="name">
            <v-list-item-title>{{ name | capitalize }} response time </v-list-item-title>
            <v-list-item-subtitle>{{ value }} </v-list-item-subtitle>
          </v-list-item>

          <v-divider />

          <v-list-item>
            <v-list-item-title>Response rate</v-list-item-title>
            <v-list-item-subtitle>{{ item.stats?.responseRate }}% </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list
          v-if="stripe"
          title="Stripe Info"
          :item="item"
          :tab="1"
          :href="`https://dashboard.stripe.com/connect/accounts/${stripe.id}`"
        >
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ stripe.id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Email </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ stripe.email }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Payouts enabled </v-list-item-title>
            <v-list-item-subtitle>
              {{ stripe.payouts_enabled ? 'Yes' : 'No' }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Business type </v-list-item-title>
            <v-list-item-subtitle> {{ stripe.business_type | capitalize }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Company's name </v-list-item-title>
            <v-list-item-subtitle>
              {{ stripe.company?.name }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Company's phone </v-list-item-title>
            <v-list-item-subtitle>
              {{ stripe.company?.phone }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Company's address </v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ stripe.company?.address?.line1 }}, {{ stripe.company?.address?.line2 }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Company's city </v-list-item-title>
            <v-list-item-subtitle> {{ stripe.company?.address?.city }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Company's country </v-list-item-title>
            <v-list-item-subtitle> {{ stripe.company?.address?.country }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Company's postal code </v-list-item-title>
            <v-list-item-subtitle> {{ stripe.company?.address?.postal_code }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Requirements Due </v-list-item-title>
            <v-list-item-subtitle>
              <div v-for="(due, index) in stripe.requirements.eventually_due" :key="index" class="my-1">
                {{ due }}
              </div>
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AccountService, PaymentService } from '@tutti/services';
import { ACCOUNT_TYPE_LABEL, ACCOUNT_FOLLOW_UP_QUESTIONS } from '@tutti/constants';
import { FeatureMixin } from '@tutti/mixins';

export default {
  mixins: [FeatureMixin],

  data() {
    return {
      item: null,
      count: {},
      itemsObject: {},
      calendarInfo: {},
      stripe: null,
    };
  },

  async created() {
    this.getCount();
    await this.getAccount();
    await this.getAccountIntegration();
    this.getStripeInfo();

    this.$setFeatures({
      disciplines: true,
    });

    this.itemsObject = this.$reduce(ACCOUNT_FOLLOW_UP_QUESTIONS, 'value', 'question');
  },

  methods: {
    async getAccount() {
      const response = await AccountService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(`${this.item.name} - ${ACCOUNT_TYPE_LABEL[this.item.type]}`);
      }
    },
    async getAccountIntegration() {
      const response = await AccountService.accountIntegration(this.$route.params.id);
      if (response.data) {
        this.calendarInfo = response.data;
      }
    },
    async getStripeInfo() {
      if (this.item.stripe.accountId) {
        const response = await PaymentService.getAccount(this.item._id);
        if (response) {
          this.stripe = response.data;
        }
      }
    },

    async getCount() {
      const response = await AccountService.count(this.$route.params.id);
      if (response) {
        this.count = response.data;
      }
    },
  },
};
</script>
